/**
 * @author mrdoob / http://mrdoob.com
 * @author Mugen87 / https://github.com/Mugen87
 */

const VRButton = {
  createButton: function(renderer, button, type, scene, camera) {
    // if (options && options.referenceSpaceType) {
    //   renderer.xr.setReferenceSpaceType(options.referenceSpaceType);
    // }
    function showEnterVR(/*device*/) {
      let currentSession = null;

      function onSessionStarted(session) {
        window.session = session;

        session.addEventListener("end", onSessionEnded);

        renderer.xr.setSession(session);
        // button.textContent = "EXIT VR";
        const scenepositionX = scene.position.x;
        const scenepositionZ = scene.position.z;
        scene.position.set(0, 0, 0);
        camera.rotation.set(0, 0, 0);
        scene.rotation.y = -3.14;
        scene.position.set(scenepositionX, 0, scenepositionZ);

        currentSession = session;
      }

      function onSessionEnded(/*event*/) {
        window.mergin_mode.realities.virtual();
        currentSession.removeEventListener("end", onSessionEnded);

        button.textContent = "ENTER VR";
        const scenepositionX = scene.position.x;
        const scenepositionZ = scene.position.z;
        scene.position.set(scenepositionX, -1.7, scenepositionZ);
        camera.position.set(0, 0, 0);
        camera.rotation.set(0, 0, 0);
        scene.rotation.y = 0;
        scene.position.y = -1.7;
        currentSession = null;
      }

      //

      // button.style.display = "";

      // button.style.cursor = "pointer";
      // button.style.left = "calc(50% - 50px)";
      // button.style.width = "100px";

      // button.textContent = "ENTER VR";

      // button.onmouseenter = function() {
      //   // button.style.opacity = "1.0";
      // };

      // button.onmouseleave = function() {
      //   // button.style.opacity = "0.5";
      // };

      button.onclick = function() {
        if (currentSession === null) {
          // WebXR's requestReferenceSpace only works if the corresponding feature
          // was requested at session creation time. For simplicity, just ask for
          // the interesting ones as optional features, but be aware that the
          // requestReferenceSpace call will fail if it turns out to be unavailable.
          // ('local' is always available for immersive sessions and doesn't need to
          // be requested separately.)

          const sessionInit = {
            optionalFeatures: ["local-floor", "bounded-floor"],
          };
          navigator.xr.requestSession(type, sessionInit).then(onSessionStarted);
        } else {
          currentSession.end();
        }
      };
    }

    function disableButton() {
      button.style.textDecoration = "line-through ";
      button.style.pointerEvents = "none";
      // button.style.cursor = "auto";
      // button.style.left = "calc(50% - 75px)";
      // button.style.width = "150px";

      // button.onmouseenter = null;
      // button.onmouseleave = null;

      button.onclick = null;
    }

    function showWebXRNotFound() {
      disableButton();

      button.style.textDecoration = "line-through";
      button.style.pointerEvents = "none";
      // button.textContent = "VR NOT SUPPORTED";
    }

    function stylizeElement(element) {
      // element.style.position = "absolute";
      // element.style.bottom = "20px";
      // element.style.padding = "12px 6px";
      // element.style.border = "1px solid #fff";
      // element.style.borderRadius = "4px";
      // element.style.background = "rgba(0,0,0,0.1)";
      // element.style.color = "#fff";
      // element.style.font = "normal 13px sans-serif";
      // element.style.textAlign = "center";
      // element.style.opacity = "0.5";
      // element.style.outline = "none";
      // element.style.zIndex = "999";
    }

    if ("xr" in navigator) {
      // button.style.display = "none";

      // stylizeElement(button);

      navigator.xr.isSessionSupported("immersive-vr").then(function(supported) {
        supported ? showEnterVR() : showWebXRNotFound();
      });

      return button;
    } else {
      const message = document.createElement("a");
      message.href = "https://immersiveweb.dev/";

      if (window.isSecureContext === false) {
        message.innerHTML = "WEBXR NEEDS HTTPS"; // TODO Improve message
      } else {
        message.innerHTML = "WEBXR NOT AVAILABLE";
      }

      message.style.left = "calc(50% - 90px)";
      message.style.width = "180px";
      message.style.textDecoration = "none";

      stylizeElement(message);

      return message;
    }
  },
};

export { VRButton };
